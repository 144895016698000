































































































































































































































































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'
import axios, { CancelTokenSource, CancelToken } from 'axios'
// import debounce from 'lodash.debounce'
import AdoriService from '@/services/adori'
import { ADORI_SSO_BASE_URL } from '@/constants'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

const validations = {
  newPodcastersEmail: {
    required,
    email,
  },
  existingPodcastersEmail: {
    required,
    email,
  },
}
@Component({
  // @ts-ignore
  validations,
})
export default class ViewRequestInvite extends Mixins(validationMixin) {
  selectedPersona: string = 'EXISTING_PODCASTER' // 'EXISTING_PODCASTER' or 'NEW_PODCASTER'

  podcastSearchText: string = ''
  isSearchingPodcasts: boolean = false
  hasSearchedPodcasts: boolean = false
  podcastSearchCancelTokenSource: CancelTokenSource | null = null

  rssSearchText: string = ''
  isSearchingRss: boolean = false
  hasSearchedRss: boolean = false

  searchResults: any[] = []
  selectedPodcastUid: string = ''

  newPodcastersEmail: string = ''
  existingPodcastersEmail: string = ''
  descriptionText: string = ''

  isRequestingInvite: boolean = false
  newPodcastersEmailInvite: boolean = false
  existingPodcastersEmailInvite: boolean = false

  showModal: boolean = false

  totalPodcasts: number = 0
  nextPage: number = 0
  allSearchResults: any[] = []

  async mounted() {
    window.scrollTo(0, 0)
    window.location.href = '/signup'
  }

  get searchContentHeight() {
    // @ts-ignore
    let searchBoxBottom = this.$refs.searchBox.getBoundingClientRect().bottom
    // let searchBoxStyle = window.getComputedStyle(this.$refs['searchBox'])
    return `min-height: 300px; height: calc(100vh - ${searchBoxBottom + 105}px)`
  }

  async onPodcastSearch() {
    if (this.podcastSearchText.length !== 0) {
      if (this.podcastSearchCancelTokenSource) {
        this.podcastSearchCancelTokenSource.cancel('Search canceled by the user.')
      }
      if (this.selectedPodcastUid.length !== 0) {
        this.selectedPodcastUid = ''
      }

      try {
        this.isSearchingPodcasts = true
        this.searchResults = []
        this.allSearchResults = []
        this.podcastSearchCancelTokenSource = axios.CancelToken.source()
        let response: any = await this.searchShowsByName(
          this.podcastSearchText,
          this.podcastSearchCancelTokenSource.token
        )
        this.searchResults = response.audioCollections.external.results
        this.allSearchResults = [...this.searchResults]
        this.totalPodcasts = response.audioCollections.external.total
        this.nextPage = 0
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      } catch (e) {
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      }
    }
  }

  async searchShowsByName(podcastSearchText: string, cancelToken: CancelToken) {
    let response = await AdoriService.searchPodcasts(podcastSearchText, cancelToken)
    return response
  }

  // debouncedSearch = debounce(() => {
  //   this.onPodcastSearch()
  // }, 300)

  async onRssSearch() {
    if (this.rssSearchText.length !== 0) {
      if (this.selectedPodcastUid.length !== 0) {
        this.selectedPodcastUid = ''
      }

      try {
        this.isSearchingRss = true
        this.searchResults = []

        let response = await this.searchShowsByRss(this.rssSearchText)

        if (Object.keys(response).length !== 0) {
          this.searchResults = [response]
        }

        this.isSearchingRss = false
        this.hasSearchedRss = true
      } catch (e) {
        this.isSearchingRss = false
        this.hasSearchedRss = true
      }
    }
  }

  async searchShowsByRss(rssSearchText: string) {
    let response = await AdoriService.searchPodcastByRssUrl({
      rss: rssSearchText,
    })
    return response
  }

  selectPodcast(uid: string) {
    this.selectedPodcastUid = uid
    this.searchResults = this.searchResults.filter((result: any) => result.uid === uid)
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  async onRequestInviteExisting() {
    // @ts-ignore
    if (!this.$v.existingPodcastersEmail.$invalid) {
      try {
        this.isRequestingInvite = true

        let requestBody: any = {
          requestType: this.selectedPersona,
          emailId: this.existingPodcastersEmail,
        }
        if (this.selectedPodcastUid.length !== 0) {
          requestBody['audioCollectionUid'] = this.selectedPodcastUid
        }
        if (this.podcastSearchText.length !== 0) {
          requestBody['podcastSearchText'] = this.podcastSearchText
        }
        if (this.rssSearchText.length !== 0) {
          requestBody['rssSearchText'] = this.rssSearchText
        }

        let response = await AdoriService.requestInviteCode(requestBody)

        this.isRequestingInvite = false
        this.existingPodcastersEmailInvite = true
      } catch (e) {}
    }
  }

  async onRequestInviteNew() {
    // @ts-ignore
    if (!this.$v.newPodcastersEmail.$invalid) {
      try {
        this.isRequestingInvite = true

        let requestBody: any = {
          requestType: this.selectedPersona,
          emailId: this.newPodcastersEmail,
        }
        if (this.descriptionText.length !== 0) {
          requestBody['description'] = this.descriptionText
        }

        let response = await AdoriService.requestInviteCode(requestBody)

        this.isRequestingInvite = false
        this.newPodcastersEmailInvite = true
      } catch (e) {}
    }
  }

  handleLogin() {
    window.location.href = `${ADORI_SSO_BASE_URL}/login?redirect_uri=${encodeURIComponent(
      window.location.origin
    )}&action=login`
  }

  clearPodcastSearchText() {
    this.podcastSearchText = ''
  }

  clearRssSearchText() {
    this.rssSearchText = ''
  }

  @Watch('podcastSearchText')
  clearSearchResults() {
    if (this.podcastSearchText.length === 0) {
      this.selectedPodcastUid = ''
      this.hasSearchedPodcasts = false
      this.hasSearchedRss = false
      this.searchResults = []
    }
    if (this.podcastSearchText) {
      this.clearRssSearchText()
    }
  }

  @Watch('rssSearchText')
  clearPodcastText() {
    if (this.rssSearchText) {
      this.clearPodcastSearchText()
    }
  }

  async handleNextClick() {
    if (this.allSearchResults.length > this.nextPage + 10) {
      this.searchResults = this.allSearchResults.slice(this.nextPage + 10, this.nextPage + 20)
      this.nextPage += 10
    } else {
      try {
        this.isSearchingPodcasts = true
        this.podcastSearchCancelTokenSource = axios.CancelToken.source()
        let response: any = await AdoriService.searchPodcasts(
          this.podcastSearchText,
          this.podcastSearchCancelTokenSource.token,
          this.nextPage + 10
        )
        this.allSearchResults = [...this.allSearchResults, ...response.audioCollections.external.results]
        this.searchResults = this.allSearchResults.slice(this.nextPage + 10, this.nextPage + 20)
        this.isSearchingPodcasts = false
        this.nextPage += 10
        this.scrollToTop()
      } catch (e) {
        this.isSearchingPodcasts = false
        this.hasSearchedPodcasts = true
      }
    }
  }

  handlePreviousClick() {
    this.searchResults = this.allSearchResults.slice(this.nextPage - 10, this.nextPage)
    this.nextPage -= 10
    this.scrollToTop()
  }

  scrollToTop() {
    const searchResults = this.$refs.searchResults as HTMLElement
    searchResults.scrollTo(0, 0)
  }

  getPlainFormatText(text: any) {
    if (this.selectedPodcastUid) {
      return this.truncate(text, 300)
    }
    return this.truncate(text.replace(/(<([^>]+)>)/g, ' '), 130)
  }

  goToDemoPodcasts() {
    window.open('https://podcasts.adorilabs.com/adori-interactive-podcast-demos/?cid=RlRE0V9gVokkp03f', '_blank')
  }
}
