var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "flex flex-wrap justify-between bg-near-black nh4 ph4 pv1",
      },
      [
        _c("div", [
          _c("img", {
            staticClass: "db pv2 pointer mw-img",
            attrs: { src: require("@/assets/adori-logo-studio.svg") },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          }),
        ]),
        _c("div", { staticClass: "flex items-center" }, [
          _c("span", [
            _c("span", { staticClass: "silver dn di-l" }, [
              _vm._v("Already signed up?  "),
            ]),
            _c(
              "span",
              {
                staticClass: "light-gray link dim pointer",
                on: { click: _vm.handleLogin },
              },
              [_vm._v("Login")]
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "flex flex-column ml-auto mr-auto m-100",
        staticStyle: {
          "min-height": "90vh",
          "margin-left": "-2rem",
          "margin-right": "-2rem",
        },
      },
      [
        _c("div", { staticClass: "main-layout" }, [
          _c(
            "div",
            {
              staticClass:
                "main-layout-left flex-column items-center justify-center pt1",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "main-layout-left-card flex flex-column br2 ph3 pv3 relative",
                  staticStyle: { "background-color": "#313131" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "light-gray mb2 tc",
                      staticStyle: { "font-size": "3vh" },
                    },
                    [_vm._v("Request an invite")]
                  ),
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "o-60 white lh-copy mb2",
                      staticStyle: { "font-size": "2vh" },
                    },
                    [
                      _vm._v(
                        "\n            We are now inviting podcasters, publishers, enterprises, and brands who want to unlock new value from\n            their content.\n          "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "light-gray br2 link pointer dim mt2 tc bg-adori-red",
                      staticStyle: {
                        "padding-top": "10px",
                        "padding-bottom": "10px",
                        "font-size": "2.1vh",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showModal = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            Interactive audio experience demo\n          "
                      ),
                    ]
                  ),
                  _c("img", {
                    staticClass: "absolute top-1 right-1",
                    attrs: {
                      src: require("@/assets/section_highlight.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "main-layout-left-card flex flex-column br2 ph3 pv3 relative",
                  staticStyle: {
                    "background-color": "#313131",
                    "margin-top": "2.5vh",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "light-gray mb2 tc",
                      staticStyle: { "font-size": "3vh" },
                    },
                    [_vm._v("Why Adori Studio?")]
                  ),
                  _vm._m(1),
                  _c(
                    "div",
                    {
                      staticClass: "o-60 white lh-copy mb2",
                      staticStyle: { "font-size": "2vh" },
                    },
                    [
                      _vm._v(
                        "\n            Transform audio into beautiful interactive experiences that maximizes listener engagement and\n            monetization. Access multi-format publishing and advanced analytics.\n          "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "light-gray br2 link pointer dim mt2 tc bg-adori-red",
                      staticStyle: {
                        "padding-top": "10px",
                        "padding-bottom": "10px",
                        "font-size": "2.1vh",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goToDemoPodcasts()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            Interactive snippets of popular podcasts\n          "
                      ),
                    ]
                  ),
                  _c("img", {
                    staticClass: "absolute top-1 right-1",
                    attrs: {
                      src: require("@/assets/section_highlight.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "main-layout-right",
              staticStyle: { "border-left": "2px solid #313131" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex b--adori-light-gray w-100",
                  staticStyle: { "border-bottom": "2px solid #313131" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex w-100 ml-auto mr-auto",
                      staticStyle: { width: "90%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "light-gray pointer glow pb2 pv3 f5 fw5 ml1",
                          class:
                            _vm.selectedPersona === "EXISTING_PODCASTER"
                              ? "bb bw1 o-100"
                              : "o-70",
                          on: {
                            click: function ($event) {
                              _vm.selectedPersona = "EXISTING_PODCASTER"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              I already have a podcast\n            "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "light-gray ml4 pointer glow pv3 f5 fw5",
                          class:
                            _vm.selectedPersona === "NEW_PODCASTER"
                              ? "bb bw1  o-100"
                              : "o-70",
                          on: {
                            click: function ($event) {
                              _vm.selectedPersona = "NEW_PODCASTER"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              I am starting a new podcast\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex justify-center ml-auto mr-auto",
                  staticStyle: { width: "90%" },
                },
                [
                  _vm.selectedPersona === "EXISTING_PODCASTER"
                    ? _c("div", { staticClass: "w-100" }, [
                        !_vm.existingPodcastersEmailInvite
                          ? _c(
                              "form",
                              {
                                staticClass: "light-gray lh-copy w-100 pv3",
                                class: _vm.isRequestingInvite
                                  ? "o-50 not-allowed"
                                  : "",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onRequestInviteExisting.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "silver mb3" }, [
                                  _vm._v(
                                    "That's great! In 3 simple steps, you can start Adorifying your podcast."
                                  ),
                                ]),
                                _vm._m(2),
                                _c(
                                  "div",
                                  {
                                    ref: "searchBox",
                                    staticClass:
                                      "flex flex-wrap flex-column flex-row-l justify-between-l items-start-l mb2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "search-input-podcast flex items-center mv2 relative",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.podcastSearchText,
                                              expression: "podcastSearchText",
                                            },
                                          ],
                                          staticClass:
                                            "w-100 input-reset bg-adori-light-gray bn h-50 light-gray pa3 br3 br--left pr4",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Search for your podcasts",
                                            autofocus: "",
                                          },
                                          domProps: {
                                            value: _vm.podcastSearchText,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.podcastSearchText =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _vm.podcastSearchText
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons absolute silver pointer dim f4",
                                                staticStyle: { right: "64px" },
                                                on: {
                                                  click:
                                                    _vm.clearPodcastSearchText,
                                                },
                                              },
                                              [_vm._v("close")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "h-50 ph3 bg-adori-red bn light-gray flex justify-center items-center br3 br--right link pointer dim",
                                            class: _vm.isSearchingPodcasts
                                              ? "o-50"
                                              : "",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.onPodcastSearch()
                                              },
                                            },
                                          },
                                          [
                                            !_vm.isSearchingPodcasts
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "f3 material-icons",
                                                  },
                                                  [_vm._v("search")]
                                                )
                                              : _c("div", {
                                                  staticClass:
                                                    "loader loader-inline",
                                                }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "silver mv2 mt3-l pt1-l tc",
                                      },
                                      [_vm._v("Or")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "search-input-podcast flex items-center mv2 relative",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.rssSearchText,
                                              expression: "rssSearchText",
                                            },
                                          ],
                                          staticClass:
                                            "w-100 input-reset bg-adori-light-gray bn h-50 light-gray pa3 br3 br--left pr4",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Add link to your RSS feed",
                                          },
                                          domProps: {
                                            value: _vm.rssSearchText,
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.onRssSearch()
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.rssSearchText =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _vm.rssSearchText
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons absolute silver pointer dim f4",
                                                staticStyle: { right: "64px" },
                                                on: {
                                                  click: _vm.clearRssSearchText,
                                                },
                                              },
                                              [_vm._v("close")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "h-50 ph3 bg-adori-red bn light-gray flex justify-center items-center br3 br--right link pointer dim",
                                            class: _vm.isSearchingRss
                                              ? "o-50"
                                              : "",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.onRssSearch()
                                              },
                                            },
                                          },
                                          [
                                            !_vm.isSearchingRss
                                              ? _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "f3 material-icons",
                                                  },
                                                  [_vm._v("search")]
                                                )
                                              : _c("div", {
                                                  staticClass:
                                                    "loader loader-inline",
                                                }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.hasSearchedPodcasts || _vm.hasSearchedRss
                                  ? _c("div", {}, [
                                      !_vm.isSearchingPodcasts &&
                                      !_vm.isSearchingRss &&
                                      _vm.searchResults.length === 0
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "silver mb3 lh-copy fw5 mr4-l",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    We were unable to find this podcast in our records. May we ask you to check for any possible\n                    errors and try searching again?\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "silver mb3 lh-copy mr4-l",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    However, if you are confident that what you have submitted above is correct, please continue and\n                    provide us the email address you have used in the RSS feed of your podcast, so that we run a\n                    manual search and confirm this. It also allows us to contact you if we need any additional\n                    information.\n                  "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm.searchResults.length === 1
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "light-gray mb1" },
                                              [
                                                _vm._v(
                                                  "2 . Select your podcast"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-100 mb3" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pa3 bg-adori-light-gray",
                                                    class:
                                                      _vm.searchResults[0]
                                                        .uid ===
                                                      _vm.selectedPodcastUid
                                                        ? "b--adori-red ba bw1 br3"
                                                        : "link pointer dim",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectPodcast(
                                                          _vm.searchResults[0]
                                                            .uid
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "f5 mb3" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.searchResults[0]
                                                              .name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "cf" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fl w-30 mw4",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: _vm
                                                                  .searchResults[0]
                                                                  .imageUrl,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fl w-70",
                                                          },
                                                          [
                                                            _vm.searchResults[0]
                                                              .publisher
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "f6 fw5 silver ml3 mb2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            By " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .searchResults[0]
                                                                            .publisher
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm.searchResults[0]
                                                              .description
                                                              ? _c("div", {
                                                                  staticClass:
                                                                    "f6 light-gray ml3",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        _vm.getPlainFormatText(
                                                                          _vm
                                                                            .searchResults[0]
                                                                            .description
                                                                        )
                                                                      ),
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                            _vm.searchResults[0]
                                                              .totalTracks
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "f6 silver ml3",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .searchResults[0]
                                                                            .totalTracks
                                                                        ) +
                                                                        " episodes\n                          "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm.podcastSearchText !== ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "light-gray mb1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "2 . Select your podcast"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.podcastSearchText
                                              ? _c(
                                                  "div",
                                                  {
                                                    ref: "searchResults",
                                                    staticClass:
                                                      "podcasts overflow-y-auto scrollbar",
                                                    style:
                                                      _vm.searchContentHeight,
                                                  },
                                                  _vm._l(
                                                    _vm.searchResults,
                                                    function (podcast) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: podcast.uid,
                                                          staticClass:
                                                            "pa3 bg-adori-light-gray",
                                                          class:
                                                            podcast.uid ===
                                                            _vm.selectedPodcastUid
                                                              ? "b--adori-red ba bw1 br3"
                                                              : "link pointer dim",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectPodcast(
                                                                podcast.uid
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "f5 mb3 mw5-l truncate-l",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    podcast.name
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass: "cf",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fl w-30",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: podcast.imageUrl,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fl w-70",
                                                                },
                                                                [
                                                                  podcast.publisher
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "f6 fw5 silver ml3 mb2 mw5-l truncate-l",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            By " +
                                                                              _vm._s(
                                                                                podcast.publisher
                                                                              ) +
                                                                              "\n                          "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  podcast.description
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "f6 light-gray ml3 mb2",
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _vm.getPlainFormatText(
                                                                                    podcast.description
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  podcast.totalTracks
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "f6 silver ml3",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                podcast.totalTracks
                                                                              ) +
                                                                              " episodes\n                          "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _vm.searchResults.length !== 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex mt3 justify-between items-center",
                                                  },
                                                  [
                                                    _c("BaseButtonBorder", {
                                                      attrs: {
                                                        text: "Prev",
                                                        disabled:
                                                          _vm.nextPage === 0,
                                                        onClick:
                                                          _vm.handlePreviousClick,
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "silver f6",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Page\n                      " +
                                                            _vm._s(
                                                              (
                                                                _vm.nextPage /
                                                                  10 +
                                                                1
                                                              ).toLocaleString(
                                                                "en-US"
                                                              ) +
                                                                " of " +
                                                                Math.ceil(
                                                                  _vm.totalPodcasts /
                                                                    10
                                                                ).toLocaleString(
                                                                  "en-US"
                                                                )
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("BaseButtonBorder", {
                                                      attrs: {
                                                        text: "Next",
                                                        disabled:
                                                          _vm.nextPage >=
                                                          _vm.totalPodcasts -
                                                            10,
                                                        onClick:
                                                          _vm.handleNextClick,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                    ])
                                  : _vm._e(),
                                (_vm.hasSearchedPodcasts ||
                                  _vm.hasSearchedRss) &&
                                _vm.selectedPodcastUid
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "light-gray mb1" },
                                        [_vm._v("3⁯. Confirm your identity")]
                                      ),
                                      _c("div", { staticClass: "mt2 mb3" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "br3 w-100",
                                            class:
                                              _vm.$v.existingPodcastersEmail
                                                .$invalid &&
                                              _vm.$v.existingPodcastersEmail
                                                .$dirty
                                                ? "ba b--adori-red"
                                                : "",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value:
                                                    _vm.$v
                                                      .existingPodcastersEmail
                                                      .$model,
                                                  expression:
                                                    "$v.existingPodcastersEmail.$model",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass:
                                                "w-100 input-reset bg-adori-light-gray bn h-50 light-gray pa3 br3",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Enter the registered email ID used while publishing the RSS feed",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.$v.existingPodcastersEmail
                                                    .$model,
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  $event.preventDefault()
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.$v
                                                      .existingPodcastersEmail,
                                                    "$model",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm.$v.existingPodcastersEmail.$dirty
                                          ? _c("div", [
                                              !_vm.$v.existingPodcastersEmail
                                                .required
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f6 adori-red",
                                                    },
                                                    [_vm._v("Cannot be empty.")]
                                                  )
                                                : _vm._e(),
                                              !_vm.$v.existingPodcastersEmail
                                                .email
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f6 adori-red",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Must be a valid email."
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center justify-center",
                                        },
                                        [
                                          _vm.isRequestingInvite
                                            ? _c("span", {
                                                staticClass:
                                                  "dib loader loader-inline",
                                              })
                                            : _c("input", {
                                                staticClass:
                                                  "bg-adori-red light-gray ph3 pv2 bn br3 ml-auto mr-auto",
                                                class:
                                                  _vm.$v.existingPodcastersEmail
                                                    .$invalid ||
                                                  _vm.isRequestingInvite
                                                    ? "o-50 not-allowed"
                                                    : "link pointer dim",
                                                attrs: {
                                                  type: "submit",
                                                  value: "Request your invite",
                                                },
                                              }),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm.existingPodcastersEmailInvite
                          ? _c("div", { staticClass: "w-100" }, [_vm._m(3)])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.selectedPersona === "NEW_PODCASTER"
                    ? _c("div", { staticClass: "w-100" }, [
                        !_vm.newPodcastersEmailInvite
                          ? _c(
                              "form",
                              {
                                staticClass: "light-gray lh-copy w-100",
                                class: _vm.isRequestingInvite
                                  ? "o-50 not-allowed"
                                  : "",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onRequestInviteNew.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "silver mb3 mt3" }, [
                                  _vm._v(
                                    "Fantastic! We look forward to helping you create a great podcast."
                                  ),
                                ]),
                                _c("div", { staticClass: "mb4" }, [
                                  _c("div", { staticClass: "light-gray mb2" }, [
                                    _vm._v("Your email address"),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "br3 w-100",
                                      class:
                                        _vm.$v.newPodcastersEmail.$invalid &&
                                        _vm.$v.newPodcastersEmail.$dirty
                                          ? "ba b--adori-red"
                                          : "",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value:
                                              _vm.$v.newPodcastersEmail.$model,
                                            expression:
                                              "$v.newPodcastersEmail.$model",
                                            modifiers: { trim: true },
                                          },
                                        ],
                                        staticClass:
                                          "w-100 input-reset bg-adori-light-gray bn h-50 light-gray pa3 br3",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Your email address",
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.newPodcastersEmail.$model,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.$v.newPodcastersEmail,
                                              "$model",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.$v.newPodcastersEmail.$dirty
                                    ? _c("div", [
                                        !_vm.$v.newPodcastersEmail.required
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [_vm._v("Cannot be empty.")]
                                            )
                                          : _vm._e(),
                                        !_vm.$v.newPodcastersEmail.email
                                          ? _c(
                                              "div",
                                              { staticClass: "f6 adori-red" },
                                              [_vm._v("Must be a valid email.")]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "mb4" }, [
                                  _c("div", { staticClass: "light-gray mb2" }, [
                                    _vm._v(
                                      "Tell us a little bit more about your podcast (optional)"
                                    ),
                                  ]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.descriptionText,
                                        expression: "descriptionText",
                                      },
                                    ],
                                    staticClass:
                                      "w-100 h4 input-reset bg-adori-light-gray bn light-gray pa3 br3",
                                    attrs: {
                                      placeholder: "Your podcast details",
                                    },
                                    domProps: { value: _vm.descriptionText },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.descriptionText =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center mt4",
                                  },
                                  [
                                    _vm.isRequestingInvite
                                      ? _c("span", {
                                          staticClass:
                                            "dib loader loader-inline",
                                        })
                                      : _c("input", {
                                          staticClass:
                                            "bg-adori-red light-gray ph3 pv2 bn br3 ml-auto mr-auto",
                                          class:
                                            _vm.$v.newPodcastersEmail
                                              .$invalid ||
                                            _vm.isRequestingInvite
                                              ? "o-50 not-allowed"
                                              : "link pointer dim",
                                          attrs: {
                                            type: "submit",
                                            value: "Request your invite",
                                          },
                                        }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.newPodcastersEmailInvite
                          ? _c("div", { staticClass: "w-100" }, [_vm._m(4)])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
    _vm.showModal
      ? _c(
          "div",
          {
            staticClass: "modal-mask",
            on: {
              click: function ($event) {
                _vm.showModal = false
              },
            },
          },
          [
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("iframe", {
                  staticClass: "modal-iframe",
                  attrs: {
                    frameborder: "0",
                    scrolling: "no",
                    title: "Adori web player",
                    src: "https://webplayer.adorilabs.com/IPFLrJBXsgyrA29Q",
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass:
                      "absolute top-1 right-1 light-gray link pointer dim bg-adori-gray br-100 bn pt2",
                    on: {
                      click: function ($event) {
                        _vm.showModal = false
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tc" }, [
      _c("img", {
        staticClass: "card-image",
        attrs: { src: require("@/assets/podcast_image_svg.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tc mv1" }, [
      _c("img", {
        staticClass: "card-image",
        attrs: { src: require("@/assets/no-tags-new.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-between" }, [
      _c("div", { staticClass: "light-gray" }, [
        _vm._v("1 . Find your podcast"),
      ]),
      _c(
        "a",
        { attrs: { href: "https://www.listennotes.com/", target: "_blank" } },
        [_c("img", { attrs: { src: require("@/assets/listennotes.png") } })]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto mr-auto" }, [
      _c(
        "div",
        {
          staticClass: "email-invite flex flex-column pl1",
          staticStyle: { height: "85%" },
        },
        [
          _c(
            "i",
            {
              staticClass: "material-icons light-gray mb3 mt4",
              staticStyle: { "font-size": "12vh", "margin-left": "-0.8rem" },
            },
            [_vm._v("check")]
          ),
          _c(
            "div",
            {
              staticClass: "light-gray lh-title mb4",
              staticStyle: { "font-size": "3.5vh" },
            },
            [
              _vm._v(
                "\n                    Your request for an invite code to start a free trial of Adori Studio has been received!\n                  "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "silver lh-copy mb3 tj",
              staticStyle: { "font-size": "2.8vh" },
            },
            [
              _vm._v(
                "\n                    Thank you again for your interest in using the Adori platform. We provide new ways to enhance your\n                    storytelling and monetize your content, and cannot wait for you to try it.\n                  "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "silver lh-copy mb4 tj",
              staticStyle: { "font-size": "2.8vh" },
            },
            [
              _vm._v(
                "\n                    We will get back to you with an invite code or seek any additional information we may need, so\n                    keep an eye out for an email from us very soon.\n                  "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "silver lh-copy mb4",
              staticStyle: { "font-size": "2.8vh" },
            },
            [_vm._v("— Team Adori")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto mr-auto" }, [
      _c(
        "div",
        {
          staticClass: "email-invite flex flex-column pl1",
          staticStyle: { height: "85%" },
        },
        [
          _c(
            "i",
            {
              staticClass: "material-icons light-gray mb3 mt4",
              staticStyle: { "font-size": "12vh", "margin-left": "-0.8rem" },
            },
            [_vm._v("check")]
          ),
          _c(
            "div",
            {
              staticClass: "light-gray lh-title mb4",
              staticStyle: { "font-size": "3.5vh" },
            },
            [
              _vm._v(
                "\n                    Your request for an invite code to start a free trial of Adori Studio has been received!\n                  "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "silver lh-copy mb3 tj",
              staticStyle: { "font-size": "2.8vh" },
            },
            [
              _vm._v(
                "\n                    Thank you again for your interest in using the Adori platform. We provide new ways to enhance your\n                    storytelling and monetize your content, and cannot wait for you to try it.\n                  "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "silver lh-copy mb4 tj",
              staticStyle: { "font-size": "2.8vh" },
            },
            [
              _vm._v(
                "\n                    We will get back to you with an invite code or seek any additional information we may need, so\n                    keep an eye out for an email from us very soon.\n                  "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "silver lh-copy mb4",
              staticStyle: { "font-size": "2.8vh" },
            },
            [_vm._v("— Team Adori")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }